.containerClass{
    margin-inline: 10px;
    margin-block: 10px; 
} 

.singleUser{
    display: flex;
    gap : 15px;
    cursor: pointer;
}

.userImg{
    color: black;
    align-items: center;
    display: flex;
}

.userName{
    color: black;
}

.footer{
    display : flex;
    position : fixed;
    bottom : 0;
    margin : 0px 0px 15px 15px;
    width: 100%;
}

.inputBar{
    width : 20rem;
    height : 4rem;
    border-radius: 10px;
    padding: 5px;
}

.btn{
    width: 5rem;
    height: 3.9rem;
    border: 2px solid black;
    margin-left: 20px;
    border-radius: 10px;
}