.containerClass{
    margin-inline: 10px;
    margin-block: 10px; 
} 

.singleUser{
    display: flex;
    gap : 15px
}

.userImg{
    color: black;
    align-items: center;
    display: flex;   
}

.userName{
    color: black;
}
